import axios from 'axios';
import qs from 'query-string';

import API from '../constants/api';
import { appService } from './appService';
import { GUEST_REQ_TYPE, SERVICE_TYPE } from '../constants';
import { getAccessToken, getUser } from '../utils/sessionManager';

const access_token = getAccessToken();

export async function listModule(setModules) {
  const res = await axios({
    url: `${API.MODULE_LIST}`,
    method: 'get',
    headers: {
      authorization: `Bearer ${access_token}`,
      username: appService.getTenantId(),
    },
  });
  setModules(res.data);
  return res.data;
}

export async function listPublicModule(setModules) {
  const res = await axios({
    url: `${API.MODULE_SETTING_LIST}`,
    method: 'get',
    headers: {
      username: appService.getTenantId(),
    },
  });
  setModules(res.data);
  return res.data;
}
