import { Link } from "react-router-dom";
import AvendiLogo from "../../assets/images/logo.svg";

import { appService } from "../../services/appService";
import { DynamicRoutes } from "../../constants/routes";
// import LanguageSelect from "../language/LanguageSelect";

// Logo -> White kgh logo
export default function authHeader() {
  const data = appService.getTenantData();
  const logo = data.guestAppLogoUrl || data.imageUrl || AvendiLogo;
  const logoClasses = `dynamic_logo ${
    data.clientSettings?.logoLargeAndCentered && "dynamic_logo--large"
  }`;
  const logoContainerClasses = `logo add_logo ${
    data.clientSettings?.logoLargeAndCentered && "m-auto"
  }`;

  return (
    <header
      className="header_area"
      id="authHeader"
      style={{ background: "#fafafa" }}
    >
      <div className="header-row">
        <div className={logoContainerClasses}>
          <Link to={DynamicRoutes.Login}>
            <img className={logoClasses} src={logo} alt="logo" />
          </Link>
        </div>
        {!!data.clientSettings.guestAppHeaderRightLogo && (
          <div>
            <img
              src={data.clientSettings.guestAppHeaderRightLogo}
              alt="right_logo"
              className={logoClasses}
            />
          </div>
        )}

        {/* <LanguageSelect /> */}
      </div>
    </header>
  );
}
