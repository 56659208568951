const API_URL = `${process.env.REACT_APP_API_SERVER}/api`;

module.exports = {
  TENANT: `${API_URL}/tenant`,
  TRANSLATION: `${API_URL}/translation`,

  FOOD_MENU: `${API_URL}/food-menu`,
  FOOD_MENU_DETAILS: `${API_URL}/food-and-bev-category`,
  GUEST_LOGIN: `${API_URL}/auth/guest-login-anonymous`,
  GUEST_REQUEST: `${API_URL}/guest-request`,
  USERS: `${API_URL}/users`,
  SEND_EMAIL: `${API_URL}/guest-request/send_email`,
  USER_FEEDBACK: `${API_URL}/feedback-ratings`,

  // INTERNAL_SERVICE_ITEM_TYPES: `${API_URL}/internal-service/menu-types`,
  INTERNAL_SERVICE_ITEM_TYPES: `${API_URL}/internal-service-new/menu-types`,
  // INTERNAL_SERVICE_ITEMS: `${API_URL}/internal-service`,
  INTERNAL_SERVICE_ITEMS: `${API_URL}/internal-service-new`,
  INTERNAL_SERVICE_CATEGORIES: `${API_URL}/internal-service-category`,
  // INTERNAL_SERVICE_BOOKINGS: `${API_URL}/internal-service-booking`,
  INTERNAL_SERVICE_BOOKINGS: `${API_URL}/internal-service-booking-new`,

  PUBLIC__MUSEUM_ITEMS: `${API_URL}/auth/public/museum`,
  PUBLIC__MUSEUM_BOOKINGS: `${API_URL}/auth/public/museum-booking`,
  PUBLIC__MUSEUM_ITEM_TYPES: `${API_URL}/auth/public/museum/museum-item-types`,
  PUBLIC__MUSEUM_CATEGORIES: `${API_URL}/auth/public/museum-category`,
  PUBLIC__MUSEUM_PACKAGES: `${API_URL}/auth/public/museum-package`,

  // module management
  MODULE_LIST: `${API_URL}/module-settings`,
  MODULE_SETTING_LIST: `${API_URL}/hotel/module-settings`,

  //Profile
  Profile: `${API_URL}/auth/public/profile`,
  OurProperties: `${API_URL}/our-properties`,
  DinAndDrinks: `${API_URL}/dining`,
  Spa: `${API_URL}/spa`,
  Fitness: `${API_URL}/fitness`,
  ServicesType: ` ${API_URL}/different-attractions`,
};
